import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Grid } from '@mui/material';

class PersonalDetails extends Component {
  render() {
    
    let tab1 = "Experience",
      tab2 = "Education and Memberships", 
      tab3 = "Skills";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">

            <div className="row">

                <Tabs>  
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                      <Grid container spacing={3}>
                      <Grid item lg>
                        <li>
                        <h1>Feburary 2021 (ongoing)</h1>
                        <h1>AURECON | INNOVATION PARTNER</h1>
                        <p>As Aurecon’s Innovation program has evolved, we’ve brought together our human centred and design thinking approach with futures research and digital innovation – and as Innovation Partner for Aurecon’s Queensland offices, I’m lucky enough to be right in the thick of it. </p>
                        <p>Aurecon’s innovation program takes a top down and bottom up approach to ensure that our efforts are aligned to your strategic objectives and the right market signals are given the right environment to grow and scale up.</p>
                        <p>Our design thinking frameworks allow us to use client, customer and community insights to create ideas and test solutions. It provides us a way to engage with and understand the end user or customer and typically pushes us further than we may get with traditional approaches. It complements our deep technical expertise and opens us up to consider how ‘old thinking’ is unlikely to help solve the new problems we are presented with. </p>
                        <p>By applying a Futures Research lens, we’re able to examine trends and signals of change to better understand the opportunities and challenges our changing landscape presents, how they will impact us, our clients, and the industries in which we operate. </p>
                        <p>As Innovation Partner I work with our clients to explore their problems - to understand what is, explore what if and what wows. I’m responsible for key market pursuits, managing our regional innovation portfolio and supporting our internal teams and clients as they build out their core, adjacent and transformational innovation initiatives.</p>
                        <p> </p>
                        </li>
                      </Grid>
                      <Grid item lg>
                        <li>
                        <h1>March 2019 - Feburary 2021</h1>
                        <h1>AURECON | DESIGN LEAD</h1>
                        <p> As the Global Design to Innovate Lead for Aurecon’s Energy, Resources and Manufacturing (ERM) markets, I was responsible for embedding Aurecon’s Design to Innovate (D2i) framework across our internal team and applying the principals to explore our client’s wicked problems. </p>
                        <p> The D2i framework lets us use client, customer and community insights to explore and test problems, to de-risk projects and increase buy in from stakeholders by designing meaningful and collaborative ways to test options. </p>
                        <p> </p>
                        </li>
                        <br />
                        <li>
                        <h1>January 2009 - March 2019</h1>
                        <h1>AURECON | LEAD MECHANICAL ENGINEER</h1>
                        <p>I began my career as a Mechanical Engineer in Aurecon’s Resources team, focused largely on bulk materials handling and sustaining capital projects. During this time I was responsible for a range of services including;</p>
                        <p>    - Sustaining Capital portfolio management</p>
                        <p>    - Sustaining Capital and brownfield improvement studies and design</p>
                        <p>    - Engineering management</p>
                        <p>    - Design of Bulk Materials Handling and Conveyor Systems </p>
                        <p>    - Development of dynamic and static simulation models simulating various industrial applications</p>
                        <p>    - Design and analysis of bulk materials handling terminals</p>
                        </li>
                      </Grid>
                    </Grid>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                    <ul>
                    <Grid container spacing={3}>
                    <Grid item lg>
                        <li>
                        <br />
                            <h1> Master of Engineering (Engineering Science) </h1>
                            <p> University of Queensland, 2011</p>
                            <br />
                            <h1> Batchelor of Engineering (Mechanical and Space) (Hons) </h1>
                            <p> University of Queensland, 2008</p>
                        </li>
                      </Grid>
                      <Grid item lg>
                        <li>
                            <h1> Memberships </h1>
                            <p>Chartered Professional Engineer (CPEng) - Engineers Australia</p>
                            <p>National Registered Professional Engineer - Engineers Australia</p>
                            <p>Registered Professional Engineer (RPEQ) - Board of Professional Engineers of Queensland (BPEQ)</p>
                            <p>Member of the Institution of Engineers Australia</p>
                        </li>
                        </Grid>
                        </Grid>
                      </ul>
                    </div>

                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                    <ul>
                    <Grid container spacing={3}>
                    <Grid item lg>
                        <li>
                        <h1> Skills </h1>
                            <p> Exceptional collaborator with cross-functional and cross-discipline teams</p>
                            <p> Experience in leading, co-ordinating and forging a strong sense of connection within large teams spread over multiple geographies</p>
                            <p> Compelling communication skills, with ability to draw out root issues, synthesise and articulate ideas that can add value to the user</p>
                            <p> Experienced in designing intuitive process and task flows, wireframes and prototypes</p>
                            <p> Experienced in facilitation of small, medium and large (50+) groups, keeping teams on task and extracting maximum value from their time together</p>
                            <p> Experienced in conducting user research, testing and interviews</p>
                            <p> Experienced in facilitating, extracting and synthesising team and organisational strategy</p>
                            <p> Experienced in technical design and sustaining capital works</p>
                            <p> Experienced in engineering portfolio and programme management</p>
                        </li>
                      </Grid>
                      </Grid>
                      </ul>
                    </div>

                  </TabPanel>

                </Tabs>
              </div>
            </div>


        {/* End Tabs Area */}
      </div>
    );
  }
}

export default PersonalDetails;
