import React, { useState, useEffect } from "react";
import "../../css/reset.css";
import "../../css/base.css";
import "../../css/ProjectSlider.css";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



import Slider from 'react-slick';
import Modal from 'react-modal';
import projectData from '../HomeComponents/ProjectSlider/ProjectData.json';

const ProjectSlider = () => {
  const [images, setImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  useEffect(() => {
    // Set the imported JSON data to state
    setImages(projectData);
  }, []);

  const openModal = (text) => {
    setModalContent(text);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const settings = {
    dots: false,
    centerMode: false,
    swipeToSlide: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true, 
    mobileFirst: true,
    responsive: [
        {
           breakpoint: 768,
           slidesToShow: 0
        }
      ]
  };

  return (
    <div className="slider">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slider-container">
            <img 
              src={image.src} 
              alt={`Slide ${index}`} 
              onClick={() => openModal(image.text)} 
            />
          <div className="overlay">
            <p>{image.text}</p>
            </div>
          </div>
          
        ))}
      </Slider>
      <Modal className="slidermodal" isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Image Information" shouldCloseOnOverlayClick={true}>
          <div onClick={closeModal}>{modalContent}</div>
      </Modal>
      </div>
  );
};

export default ProjectSlider;
