import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { FiMail, } from "react-icons/fi";
import { Link } from "react-router-dom";


const SocialShare = [
  { Social: <FiMail />, link: "mailto:\contact@mattroskam.com" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/in/matthewroskam" },
];

const Footer = () => {
  return (
    <div className="footer-wrapper">
            <div className="footer-left"> 
              <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`}>{val.Social}</a>
                  </li>
                ))}
              </ul>
              </div>
        </div>
  );
};
export default Footer;
