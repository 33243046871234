// Import Pages
import Header from '../Components/Header';
import Hero from '../Components/HomeComponents/Hero'
import Bio from '../Components/HomeComponents/Bio'
import ProjectSlider from '../Components/HomeComponents/ProjectSlider'
import PersonalDetails from '../Components/HomeComponents/PersonalDetails'
import Footer from '../Components/Footer'
import HelmetTitle from '../Helmet';

function Home() {
  return (
    <div id="home" className="Home">
      <HelmetTitle />
      <Header />
      <Hero />
      <div id="about" className="fix"><Bio /></div>
      <div id="experience" className="fix"> <PersonalDetails  tabStyle="tab-style--1"/></div>
      <div id="projectslider" className="fix"><ProjectSlider /></div>
      <Footer />
    </div>
  );
}

export default Home;