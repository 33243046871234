import React from 'react';

function Bio() {
  return (
      <div className='bio-container'>
        <div className='bio-container-inner'>
        <h1> A BIT OF BACKGROUND... </h1>
        <p>Some problems can be solved with maths and assumptions, others just need the right people having good conversations…I’m passionate about the second type.</p>
        <p>I’m currently the Innovation Partner for Aurecon’s Queensland offices, collaborating with our internal team and our clients to build understanding, unearth innovations and support their growth. I help teams work collaboratively to ensure they have a deep understanding of their stakeholders, are solving the right problem, and that the full group are aligned to ensure that your decision making is informed and de-risked. </p>
        <p>I’m fortunate to draw on my Engineering background, delivering and leading a range of technical and advisory projects, including several large sustaining capital portfolios. This combination allows me to blend a strong technical and process driven background with project delivery experience and the blue-sky thinking of design thinking. As an engineer, the framework enables me, and my team, to solve both analytical and intuitive challenges.</p>
      </div>
      </div>
  );
}

export default Bio;