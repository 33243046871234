import image1 from '../../Media/img-home.jpg';
import { ParallaxProvider, ParallaxBanner} from "react-scroll-parallax";
import useWindowDimensions from "../WindowSize";


//const WindowWidthContext = React.createContext(0);

function Hero() {
  const { height, width } = useWindowDimensions();

  var isDisabled = false;
  var screenheight = "100vh";

    //Adjust for medium  mobile
  if (width < 991) {
    var isDisabled = true;
    var screenheight = "85vh"; 
  };

  //Adjust for small  mobile
  if (width < 575) {
    var isDisabled = true;
    var screenheight = "65vh"; 
  };

   return (
     <div className="Header-Hero">
    <ParallaxProvider>

      <ParallaxBanner
          className="Header-Hero-background"
          disabled={isDisabled}
          layers={[
              {
                  image: image1,
                  amount: 0.3,
                  expanded: false
              },
          ]}
          style={{
            height: screenheight,
            //overflow: "auto !important"
            
          }}
        >
        <div className="Header-Hero-internal"> 
          <h1>INTRODUCING MATT</h1>
          <h2> Design Thinking | Mechanical Engineering </h2> 
          </ div>
      </ParallaxBanner>
      </ ParallaxProvider>

        </div>
  );

}

export default Hero;


/*     <div className="Header-Hero">
        <Parallax bgImage={image1} strength={200}>
          <div style={ { height:"100vh"}}>
          <div className="Header-Hero-internal"> 
            <h1>INTRODUCING MATT</h1>
            <h2> Design Thinking | Mechanical Engineering </h2> 
          </div>
           </div>
        </Parallax>
        </div> */