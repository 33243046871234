import React from "react";
import {Helmet} from "react-helmet";
 
function HelmetTitle() {
    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Matt Roskam | Portfolio
                </title>
            </Helmet>
            ...
        </div>
    );
};
export default HelmetTitle;